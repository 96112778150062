import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import YourOrder from "../containers/group/summary/your-order"
import {getAmount, resetRegistration} from "../redux/group/groupAction"
import {isDefined} from "../utils/data"

const ConfirmationPaymentPage = () => {
    const dispatch      = useDispatch()
    const { members }   = useSelector(({groupReducer}) => groupReducer)
    const [amountMembers, setAmountMembers] = useState([])

    useEffect(() => {
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    const [total, setTotal] = useState(0)

    useEffect(() => {
        setTotal(0)
        members.forEach(member => {
            const category  = member.registered.registered_category
            const ticket    = isDefined(member.registered_package.idpackages) ? member.registered_package.idpackages : member.registered_package.idpackage
            const options   = member.registered_option.map(option => {
                return {
                    idoptions: option.idoptions
                }
            })

            dispatch(getAmount(category, ticket, options)).then(res => {
                setTotal(prev => prev + res)
                setAmountMembers(prev => [...prev.concat(res)])
            })
        })
    }, [members])

    return (
        <Layout main={false}>
            <SEO title="Confirmation payment" />
            <Header/>
            <div className={"page"}>
                <h2 className={"page-title"}>Well done !</h2>

                <p>
                    Thank you for your registration and payment.
                </p>

                <p>
                    In a few moments, you will receive an email containing the details of your registration. You will be able to modify and pay your registration until 10 days before the beginning of the congress.
                </p>

                <p>
                    After this date, in your personnal space, you will be able to download your access badge, your invoice and other information (the final list of exhibitors,map, etc).
                </p>

                <p>
                    Looking forward to meeting you during this new edition.
                </p>

                <YourOrder members={members} total={total} amountMembers={amountMembers}/>

                <p>
                    <strong>UNION 2020 Team</strong>
                </p>
            </div>
        </Layout>
    )
}

export default ConfirmationPaymentPage
